import { appSyncQuery } from './index';

const listString = `query ListKeys($merchant_uid: String!) {
  backOfficeKey(merchant_uid: $merchant_uid, entity_level: PARTNER) {
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}

`;

export const list = () => {
  const variables = {
    merchant_uid: 'partner',
  };
  return appSyncQuery(listString, variables, true);
};

const createString = `mutation CreateSecretKey($key_name: String, $merchant_uid: String!) {
  createBackOfficeKey(entity_level: PARTNER, merchant_uid: $merchant_uid, key_name: $key_name) {
    api_key
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}
`;

export const createKey = key_name => {
  const variables = {
    merchant_uid: 'partner',
    key_name: key_name,
  };
  return appSyncQuery(createString, variables, true);
};

const deleteString = `mutation MyMutation($key_name: String!, $merchant_uid: String!) {
  deleteBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid)
}
`;

export const deleteKey = async key_name => {
  const variables = {
    merchant_uid: 'partner',
    key_name: key_name,
  };
  return appSyncQuery(deleteString, variables, true);
};

const updateString = `mutation UpdateSecretKey($enabled: Boolean!, $key_name: String!, $merchant_uid: String!) {
  updateBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid, enabled: $enabled)
}

`;

export const updateKey = (key_name, enabled) => {
  const variables = {
    merchant_uid: 'partner',
    key_name: key_name,
    enabled: enabled,
  };
  return appSyncQuery(updateString, variables, true);
};
