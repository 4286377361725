import { appSyncQuery } from './index';

const listString = `query ListWebhooks {
  webhooks {
    endpoint
    name
    is_active
  }
}
`;

export const list = () => appSyncQuery(listString, undefined, true);

const listByEndpointString = `query ListWebhook($endpoint: String!) {
  webhooks(endpoint: $endpoint) {
    endpoint
    name
    is_active
  }
}
`;

export const listByEndpoint = (endpoint: string) =>
  appSyncQuery(listByEndpointString, { endpoint }, true);

const createString = `mutation CreateWebhook($endpoint: String!, $name: String!) {
    createWebhook(endpoint: $endpoint, name: $name)
  }
  `;

export const create = (webhook: { endpoint: string; name: string }) =>
  appSyncQuery(createString, webhook, true);

const updateString = `mutation UpdateWebhook($endpoint: String!, $name: String, $is_active: Boolean) {
    updateWebhook(endpoint: $endpoint, name: $name, is_active: $is_active)
  }
  `;

export const update = (webhook: {
  endpoint: string;
  name?: string;
  is_active?: boolean;
}) => appSyncQuery(updateString, webhook, true);

const deleteString = `mutation DeleteWebhook($endpoint: String!) {
    deleteWebhook(endpoint: $endpoint)
  }
  `;

export const deleteWebhook = (endpoint: string) => {
  return appSyncQuery(deleteString, { endpoint }, true);
};

const listEventsString = `query ListWebhookEvents($endpoint: String, $result: WebhookNotificationResult, $last_evaluated_key: String, $limit: Int) {
    webhookEvents(endpoint: $endpoint, result: $result, last_evaluated_key: $last_evaluated_key, limit: $limit) {
      events {
        id
        event
        started_at
        finished_at
        endpoint
        request
        response
        status_code
        error
        result
      }
      last_evaluated_key
    }
  }
  `;

export const listEvents = (params?: {
  endpoint?: string;
  result?: 'FAILURE' | 'IGNORED' | 'SUCCESS';
  last_evaluated_key?: string;
  limit?: number;
}) => appSyncQuery(listEventsString, params, true);

const listEventByIdString = `query ListWebhookEvents($id: ID) {
    webhookEvents(id: $id) {
      events {
        id
        event
        started_at
        finished_at
        endpoint
        request
        response
        status_code
        error
        result
      }
    }
  }
  `;

export const listEventById = (id: string) =>
  appSyncQuery(listEventByIdString, { id }, true);

const listWebhookEventsString = `query ListWebhookAndEvents($endpoint: String!, $limit: Int) {
    webhooks(endpoint: $endpoint) {
        name
        endpoint
        is_active
    }
    webhookEvents(endpoint: $endpoint, limit: $limit) {
      events {
        id
        event
        started_at
        finished_at
        endpoint
        request
        response
        status_code
        error
        result
      }
      last_evaluated_key
    }
  }
  `;

export const listWebhookEvents = (params: {
  endpoint: string;
  result?: 'FAILURE' | 'IGNORED' | 'SUCCESS';
  limit?: number;
}) => appSyncQuery(listWebhookEventsString, params, true);

const listWebhookEventByIdString = `query ListWebhookAndEvents($endpoint: String!, $id: ID!) {
    webhooks(endpoint: $endpoint) {
        name
        endpoint
        is_active
    }
    webhookEvents(id: $id) {
      events {
        id
        event
        started_at
        finished_at
        endpoint
        request
        response
        status_code
        error
        result
      }
    }
  }
  `;

export const listWebhookEventById = (params: {
  endpoint: string;
  id: string;
}) => appSyncQuery(listWebhookEventByIdString, params, true);
