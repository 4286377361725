import {
  createTableAmount,
  findPaymentMethodLogo,
  flagChip,
  formatDate,
} from '../util';
import {
  Transaction,
  TransactionReviewStatus,
} from '../../GraphQL/internal_types';

type PayorKeys = 'full_name' | 'email' | 'phone';

export type PayorOption = {
  label: string;
  value: PayorKeys;
  key: PayorKeys;
};

export const payorHeaderOptions: PayorOption[] = [
  {
    label: 'Customer Name',
    value: 'full_name',
    key: 'full_name',
  },
  {
    label: 'Email',
    value: 'email',
    key: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
    key: 'phone',
  },
];

export const generateTableRows = (
  reports: (Transaction | null)[],
  viewTransaction: (
    transaction: Transaction | null,
    showRefund: boolean,
  ) => void,
  payorColumn: PayorOption,
) => {
  return reports.map(item => {
    return {
      columns: [
        {
          className: 'amount numeric',
          content: createTableAmount(
            item?.gross_amount ?? 0,
            item?.merchant?.country_code ?? 'USA',
          ),
          type: 'basic',
          grey: ['REFUNDED', 'PARTIALLY_REFUNDED'].includes(item?.status ?? ''),
        },
        {
          className: `status`,
          text: flagChip[
            item?.flag_for_review || TransactionReviewStatus.POTENTIAL_DUPLICATE
          ]?.text,
          color:
            flagChip[
              item?.flag_for_review ||
                TransactionReviewStatus.POTENTIAL_DUPLICATE
            ]?.color,
          textColor:
            flagChip[
              item?.flag_for_review ||
                TransactionReviewStatus.POTENTIAL_DUPLICATE
            ]?.textColor,
          type: 'chip',
        },
        {
          className: 'payment-method',
          brand: findPaymentMethodLogo(item),
          lastFour: item?.payment_method?.last_four,
          type: 'paymentMethod',
        },
        {
          className: 'merchant',
          content: item?.merchant?.merchant_name,
          type: 'basic',
        },
        {
          className: 'payor-column',
          content: item?.payment_method!.payor![payorColumn.key],
          type: 'basic',
        },
        {
          className: 'payment-date',
          content: formatDate(item?.transaction_date ?? ''),
          type: 'basic',
        },
        {
          className: 'review',
          label: 'Review',
          icon: 'arrow-right',
          action: () => {
            viewTransaction(item, false);
          },
          rowObject: item,
          type: 'action',
        },
      ],
      key: `${item?.transaction_id}-row`,
      item: item,
      viewRow: () => {
        viewTransaction(item, false);
      },
    };
  });
};

export const generateTableColumns = (
  payorColumn: PayorOption,
  setPayorColumn: (option: PayorOption) => void,
) => {
  return [
    {
      className: 'amount',
      label: 'Amount',
      type: 'basic',
      width: 170,
    },
    {
      className: 'reason',
      label: 'Reason',
      type: 'chip',
      width: 170,
    },
    {
      className: 'payment-method',
      label: 'Payment Method',
      type: 'basic',
      width: 150,
    },
    {
      className: 'merchant',
      label: 'Merchant',
      type: 'basic',
    },
    {
      className: 'payor-column',
      label: payorColumn.label,
      type: 'basic',
      isDynamic: true,
      options: payorHeaderOptions,
      selected: payorColumn,
      onSelect: (item: any) => setPayorColumn(item),
    },
    {
      className: 'payment-date',
      label: 'Payment Date',
      type: 'basic',
      width: 140,
      minWidth: 140,
    },
    {
      className: 'review',
      label: '',
      action: true,
      type: 'action',
    },
  ];
};
