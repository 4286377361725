/* global FreshworksWidget */
import React, { useMemo, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  PortalHead,
  ErrorMessage,
  GeneralMessage,
  SuccessMessage,
} from '@paytheory/components.common.portal_head';
import { NavigationDrawer } from '@paytheory/components.common.navigation_drawer';

// @ts-ignore
import { BooksHooks } from '@paytheory/pay-theory-ui';

type menuItems = Array<{
  tag?: string;
  label: string;
  to: string;
  icon?: string;
  iconBrand?: string;
  badgeNumber?: number;
  isCategory?: boolean;
  subContent?: Array<{
    tag?: string;
    label: string;
    to: string;
    icon?: string;
    iconBrand?: string;
    badgeNumber?: number;
  }>;
}>;

type AdminPortalProps = {
  generateMenu: () => menuItems;
  paged: BooksHooks.Context.Page.Paged;
  logout: () => void;
};

const AdminPortal = ({ generateMenu, paged, logout }: AdminPortalProps) => {
  const portalHeadButtons = [
    {
      type: 'action',
      icon: 'question-circle',
      tooltip: 'Get Help',
      action: () => {
        // @ts-ignore
        FreshworksWidget('open');
      },
    },
    {
      type: 'action',
      icon: 'sign-out',
      tooltip: 'Sign Out',
      action: logout,
    },
  ];

  const location = useLocation();
  const pageMenu = useMemo(generateMenu, [generateMenu]) as menuItems;

  useEffect(() => {
    const state = location.state as any;
    if (state?.error) {
      ErrorMessage(state.error);
      const newState = structuredClone(state);
      newState.error = null;
      window.history.pushState(newState, '');
    } else if (state?.success) {
      SuccessMessage(state.success);
      const newState = structuredClone(state);
      newState.success = null;
      window.history.replaceState(newState, '');
    } else if (state?.message) {
      GeneralMessage(state.message);
      const newState = structuredClone(state);
      newState.message = null;
      window.history.replaceState(newState, '');
    }
  }, [location]);

  return (
    <BooksHooks.Context.Menu.Provider value={pageMenu}>
      <BooksHooks.Context.Page.Provider value={paged}>
        <header>
          <PortalHead trailingButtons={portalHeadButtons} />
        </header>
        <nav>
          <NavigationDrawer menuItems={pageMenu} />
        </nav>
        <main>
          <Outlet />
        </main>
      </BooksHooks.Context.Page.Provider>
    </BooksHooks.Context.Menu.Provider>
  );
};

export default AdminPortal;
