import React from 'react';
import {
  Button,
  ModalContent,
  closeModal, // @ts-expect-error - for some reason, this import works but the type definition is not found
} from '@paytheory/pay-theory-ui';
import { DUPLICATE_PAYMENT_MODAL_ID } from '../../constants/classes';
import {
  Transaction,
  TransactionReviewAction,
} from '../../GraphQL/internal_types';

interface TransactionReviewModalState {
  action: TransactionReviewAction | null;
  transaction: Transaction;
  actionFunction: (action: TransactionReviewAction | null) => void;
}

const TransactionReviewModalState = (
  transaction: Transaction | null,
): Record<
  TransactionReviewAction,
  {
    message: string;
    subMessage?: string;
    header: string;
    button: string;
  }
> => {
  return {
    [TransactionReviewAction.ACCEPT]: {
      message: `Are you sure you want to accept the transaction ${transaction?.transaction_id}?`,
      header: 'Approve Transaction',
      button: 'Approve',
    },
    [TransactionReviewAction.REJECT]: {
      message: `Are you sure you want to reject the transaction ${transaction?.transaction_id}?`,
      subMessage: 'This action cannot be undone.',
      header: 'Reject Transaction',
      button: 'Reject',
    },
  };
};

const TransactionReviewModal = ({
  action,
  transaction,
  actionFunction,
}: TransactionReviewModalState) => {
  const state = action
    ? TransactionReviewModalState(transaction)[action]
    : null;
  return (
    <ModalContent identifier={DUPLICATE_PAYMENT_MODAL_ID} zPosition={4}>
      <div className="filter-reset-modal-modal-content">
        <h2 className="strong">{state?.header}</h2>
        <p>{state?.message}</p>
        {state?.subMessage && (
          <p
            style={{
              color: 'var(--raspberry)',
            }}>
            {state?.subMessage}
          </p>
        )}
        <span className="button-span">
          <Button
            label={state?.button ?? ''}
            primary
            onClick={() => {
              actionFunction(action);
              closeModal(DUPLICATE_PAYMENT_MODAL_ID);
            }}
            name="filter-reset-modal"
          />
          <Button
            label="Cancel"
            onClick={() => {
              closeModal(DUPLICATE_PAYMENT_MODAL_ID);
            }}
            name="cancel-filter-reset-modal"
          />
        </span>
        {/*@ts-expect-error - style jsx is not recognized by the typescript compiler*/}
        <style jsx="true">
          {`
            #${DUPLICATE_PAYMENT_MODAL_ID}-modal-form {
              height: auto !important;
            }
            .filter-reset-modal-modal-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: auto;
            }

            .filter-reset-modal-modal-content > * {
              margin-bottom: 16px;
              text-align: center;
            }

            .filter-reset-modal-modal-content .pt-checkbox {
              margin-bottom: 0px;
              font-size: 14px !important;
            }

            .filter-reset-modal-modal-content .header-icon {
              font-size: 120px;
            }

            .filter-reset-modal-modal-content .button-span {
              display: flex;
              width: 100%;
              justify-content: space-between;
            }

            .filter-reset-modal-modal-content .button-span > * {
              width: 48% !important;
              margin-bottom: 0 !important;
            }
          `}
        </style>
      </div>
    </ModalContent>
  );
};

export default TransactionReviewModal;
