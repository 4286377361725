import { useEffect } from 'react';
import { AmplifyUser } from '@aws-amplify/ui';

export const getLocalStorage = (key: string) => (): string | null => {
  try {
    return localStorage?.getItem(key) ?? null;
  } catch (e) {
    return null;
  }
};

export const setLocalStorage = (key: string) => (value: string) => {
  try {
    localStorage?.setItem(key, value);
  } catch (e) {
    // do nothing
  }
};

export const removeLocalStorage = (key: string) => () => {
  try {
    localStorage?.removeItem(key);
  } catch (e) {
    // do nothing
  }
};

export const useUserTimeout = (
  user: AmplifyUser | null,
  signOut: () => void,
) => {
  useEffect(() => {
    // Set timeout longer for sandbox
    const minutesTillTimeout =
      process.env.REACT_APP_STAGE !== 'paytheory' ? 60 : 10;
    const timeout = minutesTillTimeout * 60000;
    const actions = ['mousemove', 'scroll', 'keydown', 'click', 'mousedown'];
    const username: string | undefined = user?.username;

    // Function to update users timestamp in local storage
    const updateTimestamp = () => {
      const timestamp = Date.now();
      if (username) {
        setLocalStorage(user?.username ?? '')(`${timestamp}`);
      }
    };

    // Function to check if user has been inactive for longer than timeout
    const checkTimestamp = () => {
      if (!username) return;
      const timestamp = getLocalStorage(username)();
      const parsedTimestamp = timestamp ? parseInt(timestamp) : 0;
      if (!parsedTimestamp) return;
      const now = Date.now();
      const diff = now - parsedTimestamp;
      if (diff > timeout) {
        signOut();
        // After 20 seconds, remove timestamp from local storage
        setTimeout(() => {
          removeLocalStorage(username)();
        }, 20000);
      }
    };

    // If no timestamp in local storage, set one
    if (username) {
      const timestamp = getLocalStorage(username)();
      if (!timestamp) {
        updateTimestamp();
      }
    }

    // Set interval to check timestamp
    const t = setInterval(checkTimestamp, 5000);

    actions.forEach(action => {
      document.addEventListener(action, updateTimestamp, {
        capture: false,
        passive: true,
      });
    });

    return () => {
      actions.forEach(action => {
        document.removeEventListener(action, updateTimestamp);
      });
      clearInterval(t);
    };
  }, [user, signOut]);
};
