import { appSyncQuery } from './index';
import * as gql from '../constants/GraphQL';
import {
  ListMerchant,
  Merchants,
  MoveDirection,
  QueryPair,
  SortDirection,
} from '../GraphQL/internal_types';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const listString = `query listMerchants($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $query: SqlQuery) {
  merchants(direction: $direction,
            limit: $limit,
            offset: $offset,
            offset_id: $offset_id,
            query: $query) {
    items {
      ach_active
      card_active
      cash_active
      country_code
      is_system
      merchant_name
      merchant_uid
      parent_merchant_uid
      submitted_onboarding
    }
    total_row_count
  }
}
`;

export const list = (
  order: SortDirection,
  offset: ListMerchant | undefined | null,
  limit: number,
  filter: Array<QueryPair>,
  direction: MoveDirection,
): Promise<GraphQLResult<{ merchants: Merchants }>> => {
  const sort = [{ key: 'merchant_name', direction: order }];
  const queryObject = {
    query_list: filter,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.merchant_uid,
    offset: offset?.merchant_name,
    limit: limit,
    direction: direction,
  };
  return appSyncQuery(listString, variables);
};

const queryString = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    merchant_uid
    merchant_name
    api_key
    submitted_onboarding
    is_system
    parent_merchant_uid
    card_active
    ach_active
    cash_active
    country_code
    fee_model {
      merchant_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
    }
  }
}
`;

export const query = (uid: string) => {
  const variables = {
    merchant_uid: uid,
  };
  return appSyncQuery(queryString, variables);
};

const createString = `mutation CreateMerchant($parent_merchant_uid: String, $merchant_name: String!, $user: MerchantUserInput) {
  createMerchant(merchant_name: $merchant_name, parent_merchant_uid: $parent_merchant_uid, user: $user) {
    ach_active
    card_active
    cash_active
    merchant_name
    merchant_uid
    parent_merchant_uid
    submitted_onboarding
  }
}
`;

export const create = (
  name: string,
  parent: string | null,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
) => {
  let user = null;
  // Only pass a user object if at least one of the fields is filled out
  if (firstName || lastName || email || phone) {
    user = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone || null,
    };
  }
  const variables = {
    merchant_name: name,
    parent_merchant_uid: parent,
    user,
  };
  return appSyncQuery(createString, variables);
};

const getNonChildrenMerchantsString = `query getNonChildrenMerchants($query: SqlQuery, $limit: Int, $offset: String, $offset_id: String) {
  merchants(query: $query, limit: $limit, offset: $offset, offset_id: $offset_id) {
    items {
      merchant_name
      merchant_uid
    }
    total_row_count
  }
}
`;
export const getNonChildrenMerchants = (
  offset: ListMerchant | null,
): Promise<GraphQLResult<{ merchants: Merchants }>> => {
  const queryObject = {
    query_list: [
      {
        key: 'parent_merchant_uid',
        operator: gql.IS_NULL,
      },
    ],
    sort_list: [{ key: 'merchant_name', direction: 'ASC' }],
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.merchant_uid,
    offset: offset?.merchant_name,
    limit: 100,
  };
  return appSyncQuery(getNonChildrenMerchantsString, variables);
};
