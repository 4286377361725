/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import React from 'react';
import PropTypes from 'prop-types';

// @ts-ignore
import { Chip } from '@paytheory/pay-theory-ui';
import { Card } from '@paytheory/components.common.card';

import {
  brandClasses,
  findPaymentMethodLogo,
  formatAmount,
  formatFullDate,
  statusChip,
} from '../../views/util';
import {
  PaymentMethodToken,
  PaymentType,
  Payor,
  Transaction,
  TransactionStatus,
} from '../../GraphQL/internal_types';

import { PayTheoryColor } from '@paytheory/components.common.global_style';

type PaymentDetailsCardProps = {
  payment: Transaction;
  country: string | undefined | null;
  border_color?: PayTheoryColor;
  title?: string;
};

const PaymentDetailsCard = ({
  payment,
  country,
  border_color,
  title,
}: PaymentDetailsCardProps) => {
  const status: TransactionStatus =
    payment?.status ?? TransactionStatus.SETTLED;
  const text = statusChip[status.toLowerCase()]?.text;
  const color = statusChip[status.toLowerCase()]?.color;
  const textColor = statusChip[status.toLowerCase()]?.textColor;

  const generateRow = (
    key: string,
    value: string,
    buttonAction?: (transaction: Transaction) => void,
  ) => {
    const valueElement = buttonAction ? (
      <p
        className="all-caps alt-text value value-button"
        onClick={() => {
          if (payment) buttonAction(payment);
        }}>
        {value}
      </p>
    ) : (
      <p className="all-caps alt-text value">{value}</p>
    );
    return (
      <div className="detail-row" key={`${key}-${value}`}>
        <p className="all-caps alt-text key">{key}:</p>
        {valueElement}
      </div>
    );
  };

  const generateTotalRow = (key: string, value: number, bold?: boolean) => {
    return (
      <div
        className={`detail-row ${bold ? 'total-row' : ''} `}
        key={`${key}-${value}`}>
        <p className="all-caps alt-text key">{`${key}:`}</p>
        <p className="all-caps alt-text value">
          {formatAmount(value, country, true)}
        </p>
      </div>
    );
  };

  const generateAddressRow = (
    key: string,
    value: Payor | PaymentMethodToken,
  ) => {
    const formattedAddress = (
      <div>
        <p className={`all-caps alt-text value`}>{value.address_line1}</p>
        {value.address_line2?.trim() && (
          <p className={`all-caps alt-text value`}>{value.address_line2}</p>
        )}
        <p
          className={`all-caps alt-text value`}>{`${value.city}, ${value.region} ${value.postal_code}`}</p>
      </div>
    );
    const valueElement = value.address_line1 ? (
      formattedAddress
    ) : (
      <p className={`all-caps alt-text value`}>No Address Provided</p>
    );
    return (
      <div className={`detail-row address`} key={key}>
        <p className="all-caps alt-text key">{`${key}:`}</p>
        {valueElement}
      </div>
    );
  };

  const validate = (
    value: string | number | undefined | null,
  ): string | false => {
    if (value !== null && value !== undefined && value !== '') {
      return `${value}`.replace(/\s/g, '');
    }
    return false;
  };

  const generateBlockOne = () => {
    const result = [];
    if (validate(payment?.transaction_id))
      result.push(generateRow('Payment ID', payment?.transaction_id ?? ''));
    if (validate(payment?.transaction_date))
      result.push(
        generateRow(
          'Payment Date',
          formatFullDate(payment?.transaction_date ?? ''),
        ),
      );
    if (validate(payment?.settlement_batch))
      result.push(
        generateRow(
          'Settlement ID',
          payment?.settlement_batch?.toString() ?? '',
        ),
      );
    if (validate(payment?.invoice?.invoice_id))
      result.push(
        generateRow('Invoice ID', payment?.invoice?.invoice_id ?? ''),
      );
    if (validate(payment?.recurring?.recurring_id))
      result.push(
        generateRow('Recurring ID', payment?.recurring?.recurring_id ?? ''),
      );
    return result.length > 0 ? (
      <div className="data-block">{result}</div>
    ) : (
      <div />
    );
  };

  const generateBlockTwo = () => {
    const result = [];
    if (validate(payment?.account_code))
      result.push(generateRow('Account Code', payment?.account_code ?? ''));
    if (validate(payment?.reference))
      result.push(generateRow('Description', payment?.reference ?? ''));

    return result.length > 0 ? (
      <div className="data-block">{result}</div>
    ) : (
      <div />
    );
  };

  const generateBlockThree = () => {
    const result = [];
    if (validate(payment?.payment_method?.payor?.full_name))
      result.push(
        generateRow(
          'Payor Name',
          payment?.payment_method?.payor?.full_name ?? '',
        ),
      );
    if (validate(payment?.payment_method?.payor?.phone))
      result.push(
        generateRow('Phone', payment?.payment_method?.payor?.phone ?? ''),
      );
    if (validate(payment?.payment_method?.payor?.email))
      result.push(
        generateRow('Email', payment?.payment_method?.payor?.email ?? ''),
      );
    if (validate(payment?.payment_method?.payor?.address_line1)) {
      // If there is any other info already in the results add a spacer
      const spacer = <div className="data-block" />;
      if (result.length > 0) result.push(spacer);
      result.push(
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        generateAddressRow('Payor Address', payment?.payment_method?.payor!),
      );
    }

    return result.length > 0 ? (
      <div className="data-block">{result}</div>
    ) : (
      <div />
    );
  };

  const generateBlockFour = () => {
    const result = [];
    if (validate(payment?.payment_method?.full_name)) {
      const title =
        payment?.payment_method?.payment_type === PaymentType.CARD
          ? 'Cardholder'
          : 'Account Holder';
      result.push(generateRow(title, payment?.payment_method?.full_name ?? ''));
    }
    if (validate(payment?.payment_method?.address_line1))
      result.push(
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        generateAddressRow('Billing Address', payment?.payment_method!),
      );
    return result.length > 0 ? (
      <div className="data-block">{result}</div>
    ) : (
      <div />
    );
  };

  const generatePaymentMethod = () => {
    return (
      <div>
        <div className="detail-row">
          <p className="all-caps alt-text key">Payment Method:</p>
          <span className="payment-method-value">
            <span
              className={`pay-theory-card-badge ${
                brandClasses[findPaymentMethodLogo(payment)] ||
                'pay-theory-card-default'
              }`}
            />
            <p className="all-caps alt-text">
              {payment?.payment_method?.payment_type === PaymentType.CASH
                ? 'CASH'
                : `**${payment?.payment_method?.last_four ?? ''}`}
            </p>
          </span>
        </div>
      </div>
    );
  };

  const generateAmountBlock = () => {
    const result = [];
    result.push(generateTotalRow('Gross', payment?.gross_amount ?? 0));
    result.push(generateTotalRow('Fees', payment?.fees ?? 0));
    result.push(generateTotalRow('Net', payment?.net_amount ?? 0, true));
    return result;
  };

  return (
    <Card
      border={border_color || 'white'}
      padding={'16px'}
      className="payment-detail-card">
      <h3 className="strong">{title || 'Payment Details'}</h3>
      {payment?.status ? (
        <Chip text={text} color={color} textColor={textColor} />
      ) : null}
      {generateBlockOne()}
      {generateBlockTwo()}
      {generateBlockThree()}
      {generateBlockFour()}
      {generatePaymentMethod()}
      {payment?.gross_amount ? (
        <React.Fragment>
          <div className="divider" />
          {generateAmountBlock()}
        </React.Fragment>
      ) : null}
      {/*@ts-ignore*/}
      <style jsx="true">
        {`
          .payment-detail-card .pt-chip {
            width: 100%;
            margin-bottom: 8px;
          }
          .payment-detail-card h3 {
            margin-bottom: 8px;
          }

          .payment-detail-card .detail-row {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4px;
          }

          .payment-detail-card .detail-row.address {
            margin-bottom: 8px;
          }

          .payment-detail-card .detail-row .key {
            width: 155px;
          }

          .payment-detail-card .detail-row .value {
            width: 255px;
          }

          .payment-detail-card .detail-row .value-button {
            color: var(--pt-purple);
            cursor: pointer;
          }

          .payment-detail-card .data-block {
            margin-bottom: 24px;
          }

          .payment-detail-card .pay-theory-card-badge {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50%;
            min-height: 24px;
            min-width: 38px;
            align-self: center;
            margin-right: 5px;
          }

          .payment-detail-card .payment-method-value {
            display: flex;
            align-items: center;
          }

          .payment-detail-card .divider {
            border: 1px solid var(--black);
            margin: 16px 0px;
          }

          .payment-detail-card .total-row > * {
            font-weight: var(--black-weight);
          }
        `}
      </style>
    </Card>
  );
};
PaymentDetailsCard.propTypes = {
  payment: PropTypes.object.isRequired,
  viewSettlement: PropTypes.func,
  refundPayment: PropTypes.func,
};

export default PaymentDetailsCard;
