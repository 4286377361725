/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import React from 'react';
// @ts-ignore
import { ChipDropdown, Pagination } from '@paytheory/pay-theory-ui';
import { Button } from '@paytheory/components.common.button';
import { TextField } from '@paytheory/components.common.text_field';

import { resultsPerPageOptions, paginationAction } from '../../views/util';

export type SearchFieldSettings = {
  label: string;
  value: string;
  onChange: (e: string) => void;
  openFilterSheet?: () => void;
};

type ActionBarProps = {
  onPagination: (page: paginationAction) => void;
  totalPages: number;
  updateResultsPerPage?: (results: number) => void;
  results: number;
  searchFieldSettings?: SearchFieldSettings;
  actions?: React.ReactNode;
  page: number;
  hideSearch?: boolean;
};

const ActionBar = ({
  onPagination,
  totalPages,
  updateResultsPerPage,
  results,
  searchFieldSettings,
  actions,
  page,
  hideSearch,
}: ActionBarProps) => {
  return (
    <div className="actionable-container">
      <div className="filter-field-container">
        {searchFieldSettings && !hideSearch && (
          <React.Fragment>
            <TextField
              label={searchFieldSettings.label}
              leadingIcon={{ name: 'search' }}
              value={searchFieldSettings.value}
              onChange={searchFieldSettings.onChange} // @ts-ignore
              thin
            />
            {searchFieldSettings.openFilterSheet && (
              <Button
                trailingIcon={{ name: 'sliders-v' }}
                onPress={searchFieldSettings.openFilterSheet}
                thin>
                Filters
              </Button>
            )}
          </React.Fragment>
        )}
        {actions && actions}
      </div>
      <div className="actionable-table-container">
        {updateResultsPerPage && results && (
          <React.Fragment>
            <p className={'results-label'}>Results:</p>
            <ChipDropdown
              onChange={updateResultsPerPage}
              value={results}
              options={resultsPerPageOptions}
            />
          </React.Fragment>
        )}
        <Pagination
          onPaginate={onPagination}
          totalPages={totalPages}
          page={page}
        />
      </div>
      {/* @ts-ignore */}
      <style jsx="true">
        {`
          .actionable-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }

          .filter-field-container,
          .actionable-table-container {
            display: flex;
            align-items: center;
          }

          .actionable-table-container .results-label {
            margin-right: 8px;
            padding-bottom: 4px;
          }

          .filter-field-container .pt-button {
            margin-left: 8px;
          }

          .filter-field-container .pt-text-field {
            width: 320px;
          }
        `}
      </style>
    </div>
  );
};

export default ActionBar;
