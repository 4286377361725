import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
} from 'react';

import { onPagination } from '../util';

import * as model from './model';

import { AppContext } from '../../App';
import ActionBar from '../../components/ActionBar';

import * as classes from '../../constants/classes';

import { getPaymentTableRpp, setPaymentTableRpp } from '../../settingsUtil';

import { transactions } from '../../network';

import {
  closeSpinner,
  InnerTable,
  openSheet,
  openSpinner,
  // @ts-ignore
} from '@paytheory/pay-theory-ui';

import {
  MoveDirection,
  Operator,
  SortDirection,
  Transaction,
  TransactionReviewStatus,
  Transactions as TransactionsType,
} from '../../GraphQL/internal_types';
import { listTransactionsFilter } from '../../network/transactions';
import DuplicatePaymentSheet from '../../components/DuplicatePaymentSheet';

const ManageDuplicates = () => {
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [paymentsList, setPaymentsList] = useState<
    (Transaction | null)[] | null
  >(null);
  const [totalPayments, setTotalPayments] = useState<number>(10);
  const [results, setResults] = useState<number>(
    parseInt(getPaymentTableRpp() ?? '') || 10,
  );
  const [actionable, setActionable] = useState<Transaction | null>(null);

  const [payorColumn, setPayorColumn] = useState<model.PayorOption>(
    model.payorHeaderOptions[0],
  );
  const totalPages = Math.ceil(totalPayments / results);
  const { ErrorMessage, SuccessMessage } = useContext(AppContext);

  const handleSuccess = useCallback(
    (
      response: TransactionsType | undefined,
      flip: boolean,
      message: string | null,
    ) => {
      const items = flip ? response?.items?.reverse() : response?.items;
      setPaymentsList(items ?? []);
      setTotalPayments(response?.total_row_count ?? 0);
      if (message) {
        SuccessMessage(message);
      }
      closeSpinner();
    },
    [],
  );

  const getPayments = useCallback(
    async (
      message: string | null,
      order: SortDirection,
      offset: Transaction | null,
      limit: number,
      _: null, // Here just to match the signature of the onPagination function
      direction: MoveDirection,
      flip: boolean,
    ) => {
      openSpinner();

      const finalFilter: listTransactionsFilter = {
        transactionQuery: [
          {
            key: 'flag_for_review',
            operator: Operator.EQUAL,
            value: TransactionReviewStatus.POTENTIAL_DUPLICATE,
          },
        ],
        paymentMethodQuery: [],
        payorQuery: [],
      };

      const response = await transactions.list(
        order,
        offset,
        limit,
        finalFilter,
        direction,
      );
      if (response.errors) {
        console.log('transactions.list', response.errors);
        ErrorMessage('Failed to load transactions');
        closeSpinner();
      } else {
        handleSuccess(response.data?.transactions, flip, message);
        setLoaded(true);
      }
    },
    [handleSuccess],
  );

  const viewTransaction = (item: Transaction | null) => {
    setActionable(item);
    openSheet(classes.DUPLICATE_PAYMENT_SHEET_ID);
  };

  // const closeDetailsSheet = () => {
  //   setActionable(null);
  // };

  const updateResultsPerPage = (value: number) => {
    setPaymentTableRpp(`${value}`);
    setResults(value);
    setPage(1);
    getPayments(
      null,
      SortDirection.DESC,
      null,
      value,
      null,
      MoveDirection.FORWARD,
      false,
    );
  };

  // Used to do initial fetch of the data
  useEffect(() => {
    if (loaded === false && results) {
      getPayments(
        null,
        SortDirection.DESC,
        null,
        results,
        null,
        MoveDirection.FORWARD,
        false,
      );
    }
  }, [handleSuccess, loaded, results]);

  const sheetCloseAction = (actionExecuted: boolean) => {
    if (actionExecuted)
      getPayments(
        null,
        SortDirection.DESC,
        null,
        results,
        null,
        MoveDirection.FORWARD,
        false,
      );
  };

  return (
    <Fragment>
      <h3 className={'strong payments-header'}>Flagged Transactions</h3>
      <div id={'payment-table-container'}>
        <ActionBar
          totalPages={totalPages}
          results={results}
          updateResultsPerPage={updateResultsPerPage}
          onPagination={onPagination({
            func: getPayments,
            limit: results,
            filter: null,
            totalPages: totalPages,
            totalResults: totalPayments,
            resultsArray: paymentsList || [],
            page: page,
            setPage: setPage,
          })}
          page={page}
        />
        <InnerTable
          columns={model.generateTableColumns(payorColumn, setPayorColumn)}
          rows={model.generateTableRows(
            paymentsList ?? [],
            viewTransaction,
            payorColumn,
          )}
          id="payment-table"
        />
      </div>
      <DuplicatePaymentSheet
        payment={actionable}
        setPayment={setActionable}
        closeAction={sheetCloseAction}
      />
      {/*@ts-ignore*/}
      <style jsx="true">{`
        .filter-toggle {
          margin: 0 24px 10px;
          cursor: pointer;
          width: 100px;
        }

        .filter-toggle i {
          margin-left: 5px;
        }

        .show-bar {
          height: 127px;
          overflow: hidden;
          transition: height 0.2s;
        }

        .hide-bar {
          height: 0;
          overflow: hidden;
          transition: height 0.2s;
        }

        .show-filter-open {
          transform: rotate(90deg);
          transition: transform 0.1s;
        }

        .show-filter-closed {
          transform: rotate(0deg);
          transition: transform 0.1s;
        }

        .transactions-table {
          margin: 0 24px;
        }

        #payment-table-container {
          border-radius: 20px;
          position: relative;
          background-color: var(--white);
          padding: 8px;
        }

        .reporting-container .reporting-inner-div {
          display: flex;
          border-top: 1px solid var(--black);
          border-bottom: 1px solid var(--black);
          margin: 0 0 24px;
          min-height: 315px;
        }

        .reporting-container .reporting-inner-div .payments-container {
          width: 66%;
          border-right: 1px solid var(--black);
          min-height: 250px;
        }

        .reporting-container .reporting-inner-div .settlements-container {
          width: 32%;
        }

        .reporting-container .reporting-buttons {
          display: flex;
        }

        .reporting-container .reporting-buttons .chart-button {
          padding: 8px 16px 8px 0;
          cursor: pointer;
        }

        .reporting-container .reporting-buttons .chart-button.active {
          color: var(--pt-purple);
          font-weight: var(--black-weight);
        }

        .payments-header {
          margin: 0 0 16px;
        }

        .payments-container .chart-loading {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
        }

        .payments-header {
          margin: 0 0 16px;
        }
      `}</style>
    </Fragment>
  );
};

export default ManageDuplicates;
