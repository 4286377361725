import { appSyncQuery } from './index';
import {
  ConjunctiveOperator,
  MoveDirection,
  Operator,
  QueryPair,
  SortDirection,
  SqlQuery,
  Transaction,
  TransactionReviewAction,
  Transactions,
} from '../GraphQL/internal_types';
import { GraphQLResult } from '@aws-amplify/api-graphql';

const listString = `query listTransactions($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  transactions(query: $query, offset_id: $offset_id, offset: $offset, limit: $limit, direction: $direction) {
                    items {
                        merchant_uid
                        transaction_id
                        transaction_date
                        status
                        settlement_batch
                        payment_method(query_list: $payment_method_query) {
                          card_brand
                          last_four
                          payment_type
                          payor(query_list: $payor_query) {
                            full_name
                            email
                            phone
                          }
                        }
                        merchant {
                          ach_active
                          card_active
                          cash_active
                          country_code
                          is_system
                          merchant_name
                          merchant_uid
                          parent_merchant_uid
                          submitted_onboarding
                          updated_row_at
                          __typename
                        }
                        reference
                        account_code
                        transaction_type
                        dispute_status
                        net_amount
                        gross_amount
                        fees
                        currency
                        failure_reasons
                        refunded_amount
                        parent_id
                      }
                      total_row_count
                    }
}`;

export type listTransactionsFilter = {
  transactionQuery: QueryPair[];
  paymentMethodQuery: QueryPair[];
  payorQuery: QueryPair[];
};

export const list = (
  order: SortDirection,
  offset: Transaction | null,
  limit: number,
  filter: listTransactionsFilter,
  direction: MoveDirection,
): Promise<GraphQLResult<{ transactions: Transactions }>> => {
  const sort = [{ key: 'transaction_date', direction: order }];
  const queryObject = {
    query_list: filter.transactionQuery,
    sort_list: sort,
  };
  const variables = {
    query: queryObject,
    offset_id: offset?.transaction_id,
    offset: offset?.transaction_date,
    limit: limit,
    direction: direction,
    payment_method_query: filter.paymentMethodQuery,
    payor_query: filter.payorQuery,
  };
  return appSyncQuery(listString, variables);
};

const getString = `query getTransactionAndRefunds($query: SqlQuery) {
                transactions(query: $query, limit: 100) {
                  items {
                    merchant_uid
                    transaction_id
                    transaction_date
                    status
                    settlement_batch
                    payment_method {
                          card_brand
                          last_four
                          payment_type
                          address_line1
                          address_line2
                          country
                          region
                          city
                          postal_code
                          full_name
                          payor {
                            full_name
                            email
                            phone
                            address_line1
                            address_line2
                            country
                            country
                            region
                            city
                            postal_code
                          }
                        }
                    reference
                    account_code
                    transaction_type
                    dispute_status
                    net_amount
                    gross_amount
                    fees
                    currency
                    recurring {
                        recurring_id
                    }
                    invoice {
                        invoice_id
                    }
                    failure_reasons
                    ach_return_details {
                        return_code
                        return_details
                        transfer_type
                    }
                    refunded_amount
                    refund_voidable
                    refund_reason {
                        reason_code
                        reason_details
                      }
                    parent_id
                  }
              }
            }`;

export const get = (
  transactionId: string,
): Promise<GraphQLResult<{ transactions: Transactions }>> => {
  if (transactionId.includes(':')) {
    transactionId = transactionId.split(':')[0];
  }
  const query = {
    query_list: [
      {
        key: 'transaction_id',
        value: transactionId,
        operator: Operator.EQUAL,
        conjunctive_operator: ConjunctiveOperator.OR_NEXT,
      },
      {
        key: 'parent_id',
        value: transactionId,
        operator: Operator.EQUAL,
      },
    ],
  };
  const variables = {
    query: query,
  };
  return appSyncQuery(getString, variables);
};

export const duplicate = (
  transactionId: string,
  parent_id: string,
): Promise<GraphQLResult<{ transactions: Transactions }>> => {
  const query: SqlQuery = {
    query_list: [
      {
        key: 'transaction_id',
        in_values: [transactionId, parent_id],
        operator: Operator.IN_LIST,
      },
    ],
  };
  const variables = {
    query: query,
  };
  return appSyncQuery(getString, variables);
};

const errorString = `query GetLocalizedError($error_codes: [String]!, $language_code: String!) {
  errorText(error_codes: $error_codes, language_code: $language_code)
}`;

export const getLocalizedError = (
  error_codes: Array<string>,
  language_code: string = 'en-US',
): Promise<GraphQLResult<{ errorText: Array<string> }>> => {
  const variables = {
    error_codes,
    language_code,
  };
  return appSyncQuery(errorString, variables);
};

const actOnDuplicateString = `mutation MyMutation($action: TransactionReviewAction!, $transaction_id: String!) {
  updateTransactionInReview(action: $action, transaction_id: $transaction_id)
}`;

export const actOnDuplicate = (
  action: TransactionReviewAction,
  transaction_id: string,
): Promise<GraphQLResult<{ updateTransactionInReview: boolean }>> => {
  const variables = {
    action,
    transaction_id,
  };
  return appSyncQuery(actOnDuplicateString, variables);
};
