/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import React, { useCallback, useEffect, useState } from 'react';

import {
  Sheet,
  openSpinner,
  closeSpinner,
  closeModal,
  openModal,
  closeSheet, // @ts-ignore
} from '@paytheory/pay-theory-ui';

import { Button } from '@paytheory/components.common.button';
import { ErrorMessage } from '@paytheory/components.common.portal_head';
import { transactions } from '../../network';

import {
  DUPLICATE_PAYMENT_MODAL_ID,
  DUPLICATE_PAYMENT_SHEET_ID,
} from '../../constants/classes';

import PaymentDetailsCard from '../PaymentDetailsCard';
import {
  Transaction,
  TransactionReviewAction,
} from '../../GraphQL/internal_types';
import TransactionReviewModal from '../TransactionReviewModal';

type PaymentDetailsSheetProps = {
  payment: Transaction | null;
  setPayment: (payment: Transaction | null) => void;
  zPosition?: number;
  closeAction?: (actionExecuted: boolean) => void;
};

const DuplicatePaymentSheet = ({
  payment,
  setPayment,
  zPosition,
  closeAction,
}: PaymentDetailsSheetProps) => {
  const [parentPayment, setParentPayment] = useState<Transaction | null>(null);
  const [duplicatePayment, setDuplicatePayment] = useState<Transaction | null>(
    null,
  );
  const [paymentAction, setPaymentAction] =
    useState<TransactionReviewAction | null>(null);

  const reset = (actionExectued: boolean) => {
    setParentPayment(null);
    setDuplicatePayment(null);
    setPayment(null);
    if (closeAction) closeAction(actionExectued);
  };

  const duplicatePaymentAction = (action: TransactionReviewAction | null) => {
    if (!action) return;
    openSpinner();
    transactions
      .actOnDuplicate(action, duplicatePayment?.transaction_id ?? '')
      .then(result => {
        if (result.data?.updateTransactionInReview) {
          closeModal(DUPLICATE_PAYMENT_MODAL_ID);
          closeSheet(DUPLICATE_PAYMENT_SHEET_ID);
          reset(true);
        } else {
          ErrorMessage('Error processing action');
          closeSpinner();
        }
      })
      .catch(error => {
        ErrorMessage('Error processing action');
        console.log(error);
        closeSpinner();
      });
  };

  const openActionModal = (action: TransactionReviewAction) => {
    setPaymentAction(action);
    openModal(DUPLICATE_PAYMENT_MODAL_ID);
  };

  const getPayment = useCallback(() => {
    const transactionId = payment?.transaction_id ?? '';
    const parentId = payment?.parent_id ?? '';
    transactions.duplicate(transactionId, parentId).then(response => {
      const paymentData = response?.data?.transactions?.items;
      console.log(paymentData?.length, transactionId, parentId);
      if (paymentData?.length === 2) {
        for (const item of paymentData) {
          if (item?.transaction_id === parentId) {
            setParentPayment(item);
          } else {
            setDuplicatePayment(item);
          }
        }
      } else {
        ErrorMessage('Error fetching payment details');
      }
    });
  }, [payment]);

  useEffect(() => {
    if (payment?.transaction_id) {
      getPayment();
    }
  }, [getPayment, payment]);

  return (
    <Sheet
      identifier={DUPLICATE_PAYMENT_SHEET_ID}
      closeAction={() => reset(false)}
      zPosition={zPosition}>
      {!parentPayment && (
        <div className={'grey loading-notice'}>
          Loading Transaction Details...
        </div>
      )}
      <div
        className={`payment-details-wrapper ${parentPayment ? '' : 'hidden'}`}>
        {parentPayment && duplicatePayment && (
          <React.Fragment>
            <PaymentDetailsCard
              payment={parentPayment}
              country={parentPayment.merchant?.country_code ?? 'USA'}
              border_color={'mint'}
              title={'Original Transaction'}
            />
            <br style={{ margin: '16px 0' }} />
            <PaymentDetailsCard
              payment={duplicatePayment}
              country={duplicatePayment.merchant?.country_code ?? 'USA'}
              border_color={'raspberry'}
              title={'Potential Duplicate'}
            />
            <div className={`payment-action-button`}>
              <Button
                id={'accept-duplicate-button'}
                onPress={() => openActionModal(TransactionReviewAction.ACCEPT)}
                primary
                thin
                trailingIcon={{ name: 'check' }}>
                Approve
              </Button>
              <Button
                id={'decline-duplicate-button'}
                onPress={() => openActionModal(TransactionReviewAction.REJECT)}
                thin
                trailingIcon={{ name: 'close' }}>
                Reject
              </Button>
            </div>
            <TransactionReviewModal
              action={paymentAction}
              transaction={duplicatePayment}
              actionFunction={duplicatePaymentAction}
            />
          </React.Fragment>
        )}
        {/*@ts-ignore*/}
        <style jsx="true">
          {`
            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form .refund-card-wrapper {
              margin-top: 16px;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form .loading-notice {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form .payment-details-wrapper {
              opacity: 1;
              transition: opacity 0.3s ease-out;
              height: 100%;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .payment-details-wrapper.hidden {
              opacity: 0;
              transition: opacity 0.3s ease-out;
            }

            // #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
            //   #decline-duplicate-button {
            //   color: var(--raspberry);
            // }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form .payment-action-button {
              display: flex;
              justify-content: space-around;
              align-items: center;
              padding: 16px 0px;
              overflow: hidden;
              transition: height 0.3s ease-out;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .payment-action-button
              .pt-button {
              width: 48%;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .payment-action-button.hidden,
            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .refund-card-wrapper.hidden {
              display: none;
            }

            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .payment-action-button
              .pt-button.text:not(.disabled):hover:after,
            #${DUPLICATE_PAYMENT_SHEET_ID}-sheet-form
              .payment-action-button
              .pt-button.text:not(.disabled):focus-visible:after {
              border-color: var(--black);
            }
          `}
        </style>
      </div>
    </Sheet>
  );
};

export default DuplicatePaymentSheet;
